import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, useNavigate, useLocation } from "react-router-dom";

import NavbarOne from "../siteTheme/NavbarOne";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

export default function Register(props) {
  return (
    <div>
      <NavbarOne
        setTheme={props.setTheme}
        theme={props.theme}
      />
      <div className="login-main-box">
          <div className="container">
    <div className="row justify-content-center">
      <div className="col-12 col-md-5 col-lg-5 col-xxl-5">
      <div className="login-box">
        <div className="main-head-login">
          <h3>Create Account</h3>
        </div>
        <div className="user-change">
          <ul className="list-inline">
            <li className="list-inline-item active">
              <a href="#">
                Email
              </a>
            </li>
            <li className="list-inline-item">
              <a href="#">
                Phone number
              </a>
            </li>
          </ul>
        </div>
    <Form>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Control type="email" placeholder="Enter email" />
        
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Control type="password" placeholder="Password" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="referral">
        <Form.Control type="referral" placeholder="Referral Id (Optional)" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicCheckbox">
        <Form.Check type="checkbox" label=" I have read and Agree to Bitbab's Terms of Services and Privacy Policy" />
      </Form.Group>
      <div className="d-flex justify-content-center login-action mt-5">
      <Button className="text-center" variant="primary" type="submit">
        Create account
      </Button>
      </div>
    </Form>
    </div>
      </div>
      
    </div>
   
  </div>
    </div>
    </div>
  );
}
