import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import noreplay from "../../assets/images/deposit/no-re.png";
import { makeRequest } from "../../core/services/v1/request";
import Config from "../../core/config/";
import { dateFormat } from '../../core/helper/date-format';

import $ from "jquery";
import Pagination from 'react-responsive-pagination';
import '../../pagination.css';

import Modal from 'react-bootstrap/Modal';

export default function TransactionhistoryStaking(props) {
  const navigate = useNavigate();
  const [stakingHistory, setstakingHistory] = useState([]);
  const [filter, setFilter] = useState({
    tab: 'Convert',
    type: 'Wallet Convert'
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const [totalorders, settotalorders] = useState(0);
  const [stakingBonusModal, setStakingBonusModal] = useState({show: false, list: []});

  useEffect(() => {
    $(".status_change .dropdown-item").click(function () {
      const generateStatusClass = `${$(this).attr("data-class")}-status`;
      $(this)
        .closest(".status_dropdown")
        .attr("data-color", `${generateStatusClass}`);
    });
  }, []);

  useEffect(() => {
    getHistory();
  }, [filter]);

  async function getHistory() {
    try {
      const params = { 
        url: `${Config.V1_API_URL}staking/getStakingHistory`,
        method: 'GET',
      }
      const response = (await makeRequest(params));
      if (response.status) {
        setstakingHistory(response.message)
        settotalorders(response.total);
      }
    } catch(err){}
  }
  const noOfPages = Math.ceil(totalorders / recordsPerPage);
  const clickPageNo = (pgNumber) => {
    pageChange(pgNumber);
  }
  const pageChange = (newCurrentPage) => {
    if (newCurrentPage >= 1 && newCurrentPage <= noOfPages) {
      setCurrentPage(newCurrentPage);
      getPagination(newCurrentPage)
    }
  }  
  async function getPagination(page) {
    try {
      const data = {
        limit: recordsPerPage,
        offset: recordsPerPage * (page - 1),
        filter
      };
      const params = {
        url: `${Config.V1_API_URL}staking/getStakingHistory?`+ data,
        method: "GET",
      };
      const response = await makeRequest(params);
      if (response.status && response.data) {
        setstakingHistory(response.data);
        settotalorders(response.total);
      }
    } catch (err) { }
  }
  async function getStakDailyHis(data) {
    try {
      const payload = {
        _id: data._id
      };
      const params = {
        url: `${Config.V1_API_URL}staking/getStakingHistoryDetails`,
        method: "POST",
        data: payload
      };
      const response = await makeRequest(params);
      if (response.status && response.stakingBonus) {
        setStakingBonusModal({
          show: true, list: response.stakingBonus
        });
      }
    } catch (err) { }
  }

  async function handleClose(){
    setStakingBonusModal({
      show: false, list: []
    });
  }

  return (<>
    <div className="transaction-history-second-section py-3 ">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12">
            <div className="d-flex flex-row mt-3 transaction-history-select-section flex-wrap gap-3">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="container deposit-fourth-section">
      <Modal show={stakingBonusModal.show === true} onHide={()=>handleClose()} className="modal-popup">
        <Modal.Body>
          <div className="row dropdown-container">
            <div className="col-11">
              Interest History
            </div>
            <div className="col-1 close-btn" onClick={()=>handleClose()}>
              X
            </div>
          </div>
          <hr></hr>
          <table className="table mt-4">
            <thead>
              <tr>
                <th>Date</th>
                <th>Interest</th>
              </tr>
            </thead>
            <tbody>
              {
                stakingBonusModal && stakingBonusModal.list && stakingBonusModal.list.length ?
                stakingBonusModal.list.map((item, i) => {
                  return <tr key={i}>
                    <td data-label="createdDate">{item.bonusDate}</td>
                    <td data-label="createdDate">{(item.bonus).toFixed(4)} {item.currency}</td>
                  </tr>
                }) : <tr >
                <td></td>
                <td>Record Not Found</td>
                <td></td>
              </tr>
              }
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
      <div className="row align-items-center">
        <div className="col-lg-12">
          <table className="table mt-4">
            <thead>
              <tr>
                <th>Staking Date</th>
                <th>Maturity Date</th>
                <th>Last Interest Date</th>
                <th>Next Interest Date</th>
                <th>Staking Amount</th>
                <th>Total Interest</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {(stakingHistory && stakingHistory.length > 0) ? stakingHistory.map((item, i) => {
                return <tr key={i}>
                    <td data-label="createdDate">
                    {dateFormat(item.createdDate)}
                    </td>
                    <td data-label="maturityDate">
                    {dateFormat(item.maturityDate)}
                    </td>
                    <td data-label="lastBonusDay">
                    {item.createdDate != item.lastBonusDay ? dateFormat(item.lastBonusDay):"_"}
                    </td>
                    <td data-label="nextBonusDay">
                    {item.maturityDate > item.nextBonusDay ? dateFormat(item.nextBonusDay):"_"}
                    </td>
                    <td data-label="amount"> {item.amount} {item.currency} </td>
                    <td data-label="bonus"> {(item.totalInterest).toFixed(4)} {item.currency} </td>
                    <td data-label="status">
                      <button className="btn-next-1" type="button" onClick={() => {
                        getStakDailyHis(item);
                      }}>
                        {item.status == 0 ? 'Processing' : 'Completed'}
                      </button>
                    </td>
                </tr>
                }) : <tr className="no-records-found">
                <td></td>
                <td></td>
                <td>
                  <img
                    className="no-record-image"
                    src={noreplay}
                    alt="no-record"
                  />
                  <br />
                  <span className="text-center">No Records Found</span>
                </td>
                <td></td>
                <td></td>
              </tr> }
            </tbody>
          </table>
          {totalorders > recordsPerPage &&
            <div className="row">
              <div className="col-lg-12 d-flex justify-content-end">
                <Pagination className="p2p-trade-pagination"
                  total={Math.ceil(totalorders / recordsPerPage)}
                  current={currentPage}
                  onPageChange={page => clickPageNo(page)}
                />
              </div>
            </div>
            }
          {/* {(stakingHistory && stakingHistory.length > 10) &&
          <div className="d-flex flex-row mt-4 mb-5">
            <div className="mx-auto">
              <button type="button" className="table-view-all-deposit-button-1">
                View More{" "}
                <BsFillArrowRightCircleFill className="view-more-icon" />
              </button>
              <button type="button" className="table-view-all-deposit-button-2">
                View Less{" "}
                <BsFillArrowRightCircleFill className="view-more-icon" />
              </button>
            </div>
          </div>} */}
        </div>
      </div>
    </div>
  </>
  );
}