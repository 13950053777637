import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { getHomeMarkets } from "../../../core/services/all.api";

function HomeMarket(props) {
  const navigate = useNavigate();

  const [marketValues, setMarketValues] = useState({
    USDT: [],
    // "BTC": [],
    // "INR": [],
  });
  const [marketCurrency, setMarketCurrency] = useState("USDT");

  useEffect(() => {
    getHomeMarketsCall();
  }, [marketCurrency]);

  const getHomeMarketsCall = async () => {
    const payload = { marketCurrency };
    const resp = await getHomeMarkets({ payload });
    if (resp) {
      const { data = {}, status = false } = resp;
      if (status) {
        let marketValuesCopy = Object.assign({}, marketValues);
        marketValuesCopy[payload.marketCurrency] = data;
        setMarketValues(marketValuesCopy);
      }
    }
  };

  return (
    <table className="table mt-4 whiteTable" style={{ color: "white" }}>
      <thead>
        <tr>
          <th scope="col">Pair</th>
          <th scope="col">Last Price</th>
          <th scope="col">24h Change</th>
          <th scope="col">24h Volume</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        {marketValues &&
        marketValues[marketCurrency] &&
        marketValues[marketCurrency].length > 0 ? (
          <>
            {marketValues[marketCurrency].map((data, index) => (
              <tr>
                <td
                  className="curPointer d-flex"
                >
                  {data.fromCurrency && data.fromCurrency.image && (
                    <img src={data.fromCurrency.image} alt="logo" className="img-fluid" />
                  )}{" "}
                  {data.pair && data.pair.split("_").join("/")}
                </td>
                <td>
                  {data.lastPrice
                    ? data.lastPrice.toFixed(data.decimalValue)
                    : data.lastPrice}
                </td>
                <td className={data.change >= 0 ? "color-green" : "color-red"}>
                  {data.change >= 0
                    ? "+" + data.change.toFixed(2)
                    : data.change.toFixed(2)}{" "}
                  %
                </td>
                <td>
                  {data.volume
                    ? data.volume.toFixed(data.decimalValue)
                    : data.volume}
                </td>
                <button
                    className="btn btn-cmn2"
                    onClick={() => navigate("/spot/" + data.pair)}
                >
                    Trade
                    <span>
                        <i className="bi bi-arrow-right"></i>
                    </span>
                </button>
              </tr>
            ))}
          </>
        ) : (
          <tr>
            <td></td>
            <td></td>
            <td>Pair Not Found</td>
            <td></td>
          </tr>
        )}
      </tbody>
    </table>
  );

  return (
    <table className="table mt-4 whiteTable" style={{ color: "white" }}>
      <thead>
        <tr>
          <th scope="col">Pair</th>
          <th scope="col">Last Price</th>
          <th scope="col">24h Change</th>
          <th scope="col">24h Volume</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="curPointer d-flex">
            <img
              src={require("../../../assets/newUi/img/btc.png")}
              className="img-fluid"
            />
            BNB/USDT
          </td>
          <td>554.80000000</td>
          <td className="color-red">-3.67 %</td>
          <td>1370601.44662300</td>
          <td>
            <button
              className="btn btn-cmn2"
              onClick={() => navigate("/spot/BTC_USDT")}
            >
              Trade
              <span>
                <i className="bi bi-arrow-right"></i>
              </span>
            </button>
          </td>
        </tr>
        <tr>
          <td className="curPointer d-flex">
            <img
              src={require("../../../assets/newUi/img/bnb.png")}
              className="img-fluid"
            />
            BTC/USDT
          </td>
          <td>63324.01000000</td>
          <td className="color-red">-1.24 %</td>
          <td>12091131.62870373</td>
          <td>
            <button
              className="btn btn-cmn2"
              onClick={() => navigate("/spot/BTC_USDT")}
            >
              Trade
              <span>
                <i className="bi bi-arrow-right"></i>
              </span>
            </button>
          </td>
        </tr>
        <tr>
          <td className="curPointer d-flex">
            <img
              src={require("../../../assets/newUi/img/eth.png")}
              className="img-fluid"
            />
            ETH/USDT
          </td>
          <td>2691.54000000</td>
          <td className="color-red">-2.76 %</td>
          <td>5737541.68093261</td>
          <td>
            <button
              className="btn btn-cmn2"
              onClick={() => navigate("/spot/BTC_USDT")}
            >
              Trade
              <span>
                <i className="bi bi-arrow-right"></i>
              </span>
            </button>
          </td>
        </tr>
        <tr>
          <td className="curPointer d-flex">
            <img
              src={require("../../../assets/newUi/img/trx.png")}
              className="img-fluid"
            />
            TRX/USDT
          </td>
          <td>0.16470000</td>
          <td className="color-green">+0.06 %</td>
          <td>145051.37500669</td>
          <td>
            <button
              className="btn btn-cmn2"
              onClick={() => navigate("/spot/BTC_USDT")}
            >
              Trade
              <span>
                <i className="bi bi-arrow-right"></i>
              </span>
            </button>
          </td>
        </tr>
        <tr>
          <td className="curPointer d-flex">
            <img
              src={require("../../../assets/newUi/img/btc.png")}
              className="img-fluid"
            />
            BNB/USDT
          </td>
          <td>554.80000000</td>
          <td className="color-red">-3.67 %</td>
          <td>1370601.44662300</td>
          <td>
            <button
              className="btn btn-cmn2"
              onClick={() => navigate("/spot/BTC_USDT")}
            >
              Trade
              <span>
                <i className="bi bi-arrow-right"></i>
              </span>
            </button>
          </td>
        </tr>
        <tr>
          <td className="curPointer d-flex">
            <img
              src={require("../../../assets/newUi/img/bnb.png")}
              className="img-fluid"
            />
            BTC/USDT
          </td>
          <td>63324.01000000</td>
          <td className="color-red">-1.24 %</td>
          <td>12091131.62870373</td>
          <td>
            <button
              className="btn btn-cmn2"
              onClick={() => navigate("/spot/BTC_USDT")}
            >
              Trade
              <span>
                <i className="bi bi-arrow-right"></i>
              </span>
            </button>
          </td>
        </tr>
        <tr>
          <td className="curPointer d-flex">
            <img
              src={require("../../../assets/newUi/img/eth.png")}
              className="img-fluid"
            />
            ETH/USDT
          </td>
          <td>2691.54000000</td>
          <td className="color-red">-2.76 %</td>
          <td>5737541.68093261</td>
          <td>
            <button
              className="btn btn-cmn2"
              onClick={() => navigate("/spot/BTC_USDT")}
            >
              Trade
              <span>
                <i className="bi bi-arrow-right"></i>
              </span>
            </button>
          </td>
        </tr>
        <tr>
          <td className="curPointer d-flex">
            <img
              src={require("../../../assets/newUi/img/trx.png")}
              className="img-fluid"
            />
            TRX/USDT
          </td>
          <td>0.16470000</td>
          <td className="color-green">+0.06 %</td>
          <td>145051.37500669</td>
          <td>
            <button
              className="btn btn-cmn2"
              onClick={() => navigate("/spot/BTC_USDT")}
            >
              Trade
              <span>
                <i className="bi bi-arrow-right"></i>
              </span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default HomeMarket;
