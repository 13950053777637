const RPCDETAILS = {
  ERC20: {
    explorer: {
      landing: "https://sepolia.etherscan.io/",
      txnLink: "https://sepolia.etherscan.io/tx/",
      token: "https://sepolia.etherscan.com/token/",
    },
  },
  BEP20: {
		explorer: {
      landing: "https://testnet.bscscan.com/",
      txnLink: "https://testnet.bscscan.com/tx/",
      token: "https://testnet.bscscan.com/token/",
    },
  },
  TRC20: {
		explorer: {
      landing: "https://shasta.tronscan.org/",
      txnLink: "https://shasta.tronscan.org/#/transaction/",
      token: "https://shasta.tronscan.org/#/token20/",
    },
  },
};

export default RPCDETAILS;
