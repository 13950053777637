import React, { useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { FiChevronUp, FiChevronDown } from "react-icons/fi";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import { getCookie, setCookie } from "../../../core/helper/cookie";
import { toast } from "../../../core/lib/toastAlert";
import { showPairName } from "../../../core/helper/date-format";

let initialFavs = getCookie("userPairs");

export default function PairListAndSearch(props) {
  const [isActive, setActive] = useState(false);

  const ToggleClass = () => {
    setActive(!isActive);
  };

  useEffect(() => {
    $(".spot-page-pair-select-dropdown .dropdown-menu").click(function (e) {
      e.stopPropagation();
    });
  }, []);

  const navigate = useNavigate();
  const { decimalValue, pairDetails = {}, marketList: markets = [] } = props;

  const [favPairsList, setFavPairsList] = useState([]);
  const [favPairs, setFavPairs] = useState(getCookie("userPairs"));
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [selectedTabCurrency, setSelectedTabCurrency] = useState("");
  const [listMarkets, setListMarkets] = useState([]);
  // const [toCurrencySymbolFinal, settoCurrencySymbolFinal] = useState("");
  const [searchVal, setsearchVal] = useState("");
  const [selectedMarkets, setSelectedMarkets] = useState(
    props.marketList && props.marketList.length > 0
      ? props.marketList[0].pairs
      : []
  );

  // useEffect(() => {
  //   loadSocket()
  // }, []);

  // const loadSocket = () => {
  //   props.socketConnection && props.socketConnection.on("USDTPerpetualPairResp", function (data) {
  //     console.log("USDTPerpetualPairResp : 11 : ", data);
  //     if(data && data._id) {
  //       changeSocData(data);
  //     }
  //   });
  // }

  props.socketConnection &&
    props.socketConnection.on("USDTPerpetualPairResp", function (data) {
      if (data && data._id) {
        changeSocData(data);
      }
    });

  function changeSocData(data) {
    const iDx = listMarkets.findIndex((e) => e._id == data._id);

    if (iDx > -1) {
      let listMarketsCopy = [...listMarkets, ...[]];
      let valChanged = false;

      if (listMarketsCopy[iDx].marketPrice != data.marketPrice) {
        valChanged = true;
        listMarketsCopy[iDx].marketPrice = data.marketPrice;
      }
      if (listMarketsCopy[iDx].price_24h_pcnt != data.price_24h_pcnt) {
        valChanged = true;
        listMarketsCopy[iDx].price_24h_pcnt = data.price_24h_pcnt;
      }
      if (valChanged == true) {
        setListMarkets(listMarketsCopy);
      }
    }
  }

  useEffect(() => {
    if (initialFavs != favPairs) {
      if (selectedCurrency == "") {
        setPairs("");
      }
    }
  }, [favPairs]);

  function checkFavourite(pairName) {
    const markedPairs = getCookie("userPairs");
    if (markedPairs != null && markedPairs != "") {
      let favPairs = markedPairs.split(",");
      if (favPairs.indexOf(pairName) >= 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  function setFavourite(pairName) {
    const markedPairs = getCookie("userPairs");
    let favPairsNew = [];
    if (markedPairs != null && markedPairs != "") {
      favPairsNew = markedPairs.split(",");
    }
    if (favPairsNew.indexOf(pairName) >= 0) {
      favPairsNew.splice(favPairsNew.indexOf(pairName), 1);
      toast({
        type: "success",
        message: "Favourite Pair Removed Successfully!",
      });
    } else {
      favPairsNew.push(pairName);
      toast({ type: "success", message: "Favourite Pair Added Successfully!" });
    }
    setNewFavList(favPairsNew);
    setFavPairs(favPairsNew.join(","));
    setCookie("userPairs", favPairsNew.join(","));
  }

  function setCurrency(currency) {
    console.log("setCurrency:", currency, selectedCurrency);
    if (currency != selectedCurrency) {
      setSelectedCurrency(currency);
      setPairs(currency);
    }
  }

  function setPairs(currency) {
    if (currency != "") {
      markets.map((item) => {
        if (item.currency == currency) {
          setSelectedMarkets(item.pairs);
        }
      });
    } else {
      const markedPairs = getCookie("userPairs");
      if (markedPairs != null && markedPairs != "") {
        let favouritePairs = [];
        let favPairs = markedPairs.split(",");
        markets.map((item) => {
          item.pairs.map((item1) => {
            if (favPairs.indexOf(item1.pair) >= 0) {
              favouritePairs.push(item1);
            }
          });
        });
        setSelectedMarkets(favouritePairs);
      } else {
        setSelectedMarkets([]);
      }
    }
  }

  useEffect(() => {
    if (props.toCurrencySymbol != selectedCurrency && selectedCurrency == "") {
      setSelectedCurrency(props.toCurrencySymbol);
    }
  }, [props]);

  function setNewFavList(favPairsUser = []) {
    if (selectedCurrency == "fav") {
      let newMarketList = [];
      for (let i = 0; i < markets.length; i++) {
        const marketsPairGrp = markets[i].pairs;
        console.log({ marketsPairGrp });
        for (let j = 0; j < marketsPairGrp.length; j++) {
          const marketsRow = marketsPairGrp[j];
          const favPairChk = favPairsUser.indexOf(marketsRow.pair);
          if (favPairChk > -1) {
            newMarketList.push(marketsRow);
          }
        }
      }
      setListMarkets(newMarketList);
    }
  }

  useEffect(() => {
    if (selectedCurrency) {
      if (selectedCurrency == "fav") {
        let favPairsUser = favPairs ? favPairs.split(",") : [];
        setNewFavList(favPairsUser);
      } else {
        const idx = markets.findIndex(
          (elem) => elem.currency == selectedCurrency
        );
        if (idx > -1) {
          setListMarkets(markets[idx].pairs);
        } else {
          setListMarkets([]);
        }
      }
    }
  }, [selectedCurrency, markets]);

  const { fromCurrency = {}, toCurrency = {} } = pairDetails;

  const { siteDecimal: siteDecimal_from = 2 } = fromCurrency;

  const { siteDecimal: siteDecimal_to = 2 } = toCurrency;

  return (
    <div>
      <div>
        <div className="row p-2 currency-container">
        <div className="trade-header flex-center">
              <div className="trade-market flex-center relative pointer mobile-token-container">
                <div className="row">
                  <div className="col-lg-2 d-flex align-items-center p-1 mobile-justify-content-center">
                    {pairDetails && pairDetails.fromCurrency ? (
                      <img
                        src={pairDetails.fromCurrency.image}
                        alt="icon"
                        className="icon"
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-lg-9 p-2 pt-0 pb-0 token-container ">
                    <div className="row">
                      <div className="col-lg-12 mobile-justify-content-center">
                        <span className="currency-primary" onClick={ToggleClass}>
                          {pairDetails &&
                            pairDetails.pair &&
                            showPairName(pairDetails.pair)}
                          {isActive === true ? (
                            <FiChevronUp className="ms-2" />
                          ) : (
                            <FiChevronDown className="ms-2" />
                          )}
                        </span>
                        <div
                          className={
                            isActive === false
                              ? "dropdown-content1"
                              : " dropdown-content1 dropdown-content1-onlick p-2"
                          }
                        >
                          <span>
                            <div className="row paired-trade-dropdown-tabs  pt-3">
                              <div className="col-7  paired-trade-dropdown-tabs-1 justify-content-between">
                                <ul
                                  className="nav nav-pills scroll-overflow-table"
                                  id="pills-tab"
                                  role="tablist"
                                >
                                  {markets &&
                                    markets.length > 0 &&
                                    markets.map((market, idx) => {
                                      return (
                                        <li
                                          key={idx}
                                          // className="nav-item"
                                          role="presentation"
                                          onClick={() =>
                                            setCurrency(market.currency)
                                          }
                                          className={
                                            "nav-item " +
                                            (market.currency === selectedCurrency &&
                                              "color-yellow")
                                          }
                                        >
                                          <button
                                            className={
                                              "nav-link " +
                                              (selectedCurrency ==
                                                market.currency && " active")
                                            }
                                            id={"pills-" + market.currency + "-tab"}
                                            data-bs-toggle="pill"
                                            data-bs-target={
                                              "#pills-" + market.currency
                                            }
                                            type="button"
                                            role="tab"
                                            aria-controls={
                                              "pills-" + market.currency
                                            }
                                            aria-selected="false"
                                          >
                                            {market.currency}
                                          </button>
                                        </li>
                                      );
                                    })}
                                </ul>
                              </div>
                              <div className="col-4 paired-trade-dropdown-tabs-1 ms-4">
                                <div className="input-group">
                                  <input
                                    type="text"
                                    className="form-control p-0"
                                    placeholder="S e a r c h . . ."
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    value={searchVal}
                                    onChange={(e) => {
                                      setsearchVal(e.target.value);
                                    }}
                                  />
                                  <span
                                    className="input-group-text p-0"
                                    id="basic-addon1"
                                  >
                                    <AiOutlineSearch />
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="paired-trade-dropdown-table mt-3">
                              <table className="table mb-0">
                                <thead>
                                  <tr>
                                    <th scope="col">Pair</th>
                                    <th scope="col">Last Price</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {listMarkets &&
                                    listMarkets.length > 0 &&
                                    listMarkets.map((market, i) => {
                                      const searchValue =
                                        searchVal && searchVal != ""
                                          ? searchVal.toUpperCase()
                                          : "";
                                      if (market && market.fromCurrency && market.toCurrency) {
                                        const pairNameShow = showPairName(
                                          market.pair
                                        );
                                        if (
                                          (searchValue !== "" && pairNameShow.indexOf(searchValue) > -1) ||
                                          searchValue === ""
                                        ) {
                                          return (
                                            <tr key={i}>
                                              <td>
                                                <span
                                                  className="curPointer"
                                                  onClick={() => {
                                                    navigate(
                                                      "/futures/" + market.pair
                                                    );
                                                    ToggleClass();
                                                  }}
                                                >
                                                  {pairNameShow}{" "}
                                                </span>
                                              </td>
                                              <td>
                                                <span
                                                  className="curPointer"
                                                  onClick={() => {
                                                    navigate(
                                                      "/futures/" + market.pair
                                                    );
                                                    ToggleClass();
                                                  }}
                                                >
                                                  {props.decimalValue(
                                                    market.lastPrice,
                                                    market.priceDecimal
                                                  )}
                                                </span>
                                              </td>
                                            </tr>
                                          );
                                        }
                                      }
                                    })}
                                </tbody>
                              </table>
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 d-flex align-items-center mobile-justify-content-center">
                        <span className="currency-secoundary ">
                          {pairDetails && pairDetails.fromCurrency ? pairDetails.fromCurrency.currencyName : ""}
                        </span>
                        <span className="currency-pipe-border">|</span>
                        <span className="currency-secoundary">
                          {pairDetails && pairDetails.fromCurrency ? pairDetails.fromCurrency.currencySymbol : ""} Network
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
             
              <div className="quotation-config flex-center">
              <div className="item flex nowrap pt-2 pairList-col">
                <div className="pair-primary left-side">
                  <span className="color-green-price">
                    {pairDetails && pairDetails?.marketPrice
                      ? props?.decimalValue(pairDetails?.marketPrice, pairDetails.priceDecimal, "removeZero")
                      : 0}
                  </span>
                </div>
                <div className="pair-secoundary left-side">
                  <span>
                    {" "}
                    ≈
                    {pairDetails && pairDetails?.marketPrice
                      ? props?.decimalValue(pairDetails?.marketPrice * 1, pairDetails.priceDecimal, "removeZero")
                      : 0}{" "}
                    USD
                  </span>
                </div>
              </div>
              <div className="flex-center nowrap">
              <div className="item flex nowrap pt-2">
                <div className="price-secoundary right-side">Index Price</div>
                <div className="price-primary right-side">
                  {pairDetails && pairDetails.indexPrice != undefined
                    ? props.decimalValue(
                      pairDetails.indexPrice,
                      pairDetails.priceDecimal
                    )
                    : "-"}
                </div>
              </div>
              <div className="item flex nowrap pt-2">
                <div className="price-secoundary mobile-justify-end left-side">
                  24h Change(
                  {pairDetails?.toCurrency &&
                    pairDetails?.toCurrency?.currencySymbol}
                  )
                </div>
                <div className="price-primary mobile-justify-end left-side">
                  {pairDetails && pairDetails.price24hPcnt != undefined ? (
                    <>
                      <span
                        className={
                          pairDetails.price24hPcnt >= 0
                            ? "color-green"
                            : "color-darkpink"
                        }
                      >
                        {(pairDetails.price24hPcnt >= 0 ? "+" : "") +
                          props.decimalValue(pairDetails.price24hPcnt, pairDetails.priceDecimal)}
                        %
                      </span>
                    </>
                  ) : (
                    "-"
                  )}
                </div>
              </div>
              <div className="item flex nowrap pt-2">
                <div className="price-secoundary right-side">24H High</div>
                <div className="price-primary right-side">
                  {pairDetails && pairDetails.highPrice24h != undefined
                    ? props.decimalValue(
                      pairDetails.highPrice24h,
                      pairDetails.priceDecimal
                    )
                    : "-"}
                </div>
              </div>
              <div className="item flex nowrap pt-2">
                <div className="price-secoundary mobile-justify-end left-side">24H Low</div>
                <div className="price-primary mobile-justify-end left-side">
                  {pairDetails && pairDetails.lowPrice24h != undefined
                    ? props.decimalValue(
                      pairDetails.lowPrice24h,
                      pairDetails.priceDecimal
                    )
                    : "-"}
                </div>
              </div>
              <div className="item flex nowrap pt-2">
                <div className="price-secoundary right-side">
                  24H Turnover(
                  {pairDetails.toCurrency && pairDetails.toCurrency.currencySymbol})
                </div>
                <div className="price-primary right-side">
                  {pairDetails && pairDetails.turnover24h != undefined
                    ? props.decimalValue(pairDetails.turnover24h, pairDetails.priceDecimal)
                    : "-"}
                </div>
              </div>
              </div>
              </div>
              </div>
        </div>
      </div>
    </div>
  );
}
