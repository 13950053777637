import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import jwt_decode from "jwt-decode";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import Announcement from "./Announcement";
import MainHeader from "./MainHeader";
import HomeMarket from "./HomeMarket";
import Footer from "../../siteTheme/Footer";

import { useContextData } from "../../../core/context/index";
import { getCookie, deleteCookie } from "../../../core/helper/cookie";

const MainBanner = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    $(document).ready(function () {
      if (
        window.location.pathname === "/" ||
        window.location.pathname === "/homev2"
      ) {
        // $("body").addClass("dark-new");
        // $("body").removeClass("light-new");
        // $("#themesection-body").addClass("dark-new");
        // $("#themesection-body").removeClass("light-new");

        $("body").removeClass("dark-new");
        $("body").removeClass("light-new");
        $("#themesection-body").removeClass("dark-new");
        $("#themesection-body").removeClass("light-new");
      } else if (props.theme === "light-new") {
        $("body").addClass("light-new");
        $("body").removeClass("dark-new");
      } else {
        $("body").addClass("dark-new");
        $("body").removeClass("light-new");
      }
      // if ($("#themesection-body").hasClass("light-new")) {
      //   $("body").addClass("light-new");
      //   $("body").removeClass("dark-new");
      // } else {
      // }
    });
  }, []);

  const { siteSettings, myProfile, setUserProfile } = useContextData();
  const [userLogin, setUserLogin] = useState(false);

  const loginChk = async (event) => {
    const token = getCookie("userToken");
    const decodedToken = token ? jwt_decode(token, { header: true }) : {};
    if (decodedToken.typ === "JWT") {
      setUserLogin(true);
    }
  };

  useEffect(() => {
    loginChk();
  }, [myProfile]);
  return (
    <>
      <MainHeader identify={props.identify} />
      <Container fluid className="px-0">
        <div className="full-banner-bg">
          <Row className="justify-content-md-center bg-content">
            <Col xxl={6} lg={8}>
              <div className="bannerBox">
                <h1> World's first discount broking crypto exchange </h1>
                <p>
                  Bitbab Exchange enables buy or sell Bitcoin and other cryptos
                  in real time, 24/7 with only max $1 or 0.05% fees in BITB
                  whichever is less
                </p>
                <div className="btn-box-banner">
                  <button
                    className="btn btn-cmn"
                    onClick={() => navigate("/spot/BTC_USDT")}
                  >
                    View exchange
                    <span>
                      <i className="bi bi-arrow-right"></i>
                    </span>
                  </button>
                </div>
              </div>
            </Col>
          </Row>
          <div className="video-bg">
            <div className="w-full h-screen relative">
              <video autoPlay loop muted className="w-full h-full object-cover">
                <source
                  src={require("../../../assets/newUi/img/bg-animation.mp4")}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
          <div className="announcement">{/* <Announcement /> */}</div>
        </div>
        <div className="most-secure">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xxl-8">
              <div className="row align-items-center">
                <div className="col-xl-8 col-md-8">
                  <div className="secure-title">
                    <h4>
                      The most secure digital asset trading platform of the
                      world！
                    </h4>
                    <h4>Your Security, Our Priority</h4>
                    {/* <div className="email-box">
                      <input className="form-control" placeholder="Email/Mobile"/>
                      <button className="btn btn-bitbab btn-primary">
                        Try Bitbab
                        <span>
                          <i class="bi bi-arrow-right"></i>
                        </span>
                      </button>
                    </div> */}
                  </div>
                </div>
                <div className="col-xl-4 col-md-4">
                  <div className="secure-app">
                    <div class="IntroModule_intro-thumb_3-uAa">
                      <div class="IntroModule_intro-thumb--inner_2Kjuj">
                        <div class="swiper IntroModule_swiper_1T4y7">
                          <div class="swiper-wrapper">
                            <video
                              className="w-full object-cover"
                              autoplay="autoplay"
                              muted="muted"
                              inline=""
                              loop="loop"
                              src="https://www.superex.com/static/media/SuperExCoin.ce3b7401.mp4"
                            ></video>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="new-user-exclustive mt-5">
          <div className="container">
            <div className="mt-5">
              <div className="main-title text-center mb-5">
                <div class="bg-box-trading">
                  <img
                    src={require("../../../assets/newUi/img/new-trade.png")}
                    className="img-fluid"
                  />
                </div>
                <h2>New User Exclusive</h2>
                <p>
                  Embark on your trading journey now with rewards of maximum
                  value of 1,000 USDT!
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <div className="exclusive-box">
                  <div class="item--inner-bg_active ExclusiveModule_item--inner-bg_1e7_H">
                    <div className="box-icon">
                      <img
                        src={require("../../../assets/newUi/img/user.png")}
                        className="img-fluid"
                      />
                      <div className="animation-exclusive">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="202.35"
                          height="202.35"
                          viewBox="0 0 200 200"
                          fill="none"
                          color="#FFF496"
                        >
                          <defs>
                            <linearGradient id="vykcgdhtpd">
                              <stop
                                offset="0%"
                                stop-opacity="0"
                                stop-color="currentColor"
                              ></stop>
                              <stop
                                offset="100%"
                                stop-opacity="1"
                                stop-color="currentColor"
                              ></stop>
                            </linearGradient>
                          </defs>

                          <g stroke-width="1.5">
                            <path
                              stroke="url(#vykcgdhtpd)"
                              d="M 196 100 A 96 96 0 0 1 4 100"
                            ></path>
                          </g>
                          <animateTransform
                            from="90 0 0"
                            to="-270 0 0"
                            attributeName="transform"
                            type="rotate"
                            repeatCount="indefinite"
                            dur="10300ms"
                          ></animateTransform>
                        </svg>
                      </div>
                    </div>
                    <div className="exclusive-contest">
                      <h4>Complete the registration</h4>
                      <p>
                        Register an account to get a crypto mystery box lottery
                        opportunity worth up to 1,000 USDT with a 100% winning
                        rate.
                      </p>
                      <button
                        className="btn btn-secondary btn-bitbab2"
                        onClick={() => navigate("/register")}
                      >
                        Register now
                        <span>
                          <i class="bi bi-arrow-right"></i>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="exclusive-box">
                  <div class="item--inner-bg_active ExclusiveModule_item--inner-bg_1e7_H">
                    <div className="box-icon">
                      <img
                        src={require("../../../assets/newUi/img/deposit.png")}
                        className="img-fluid"
                      />
                      <div className="animation-exclusive">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="202.35"
                          height="202.35"
                          viewBox="0 0 200 200"
                          fill="none"
                          color="#FFF496"
                        >
                          <defs>
                            <linearGradient id="vykcgdhtpd">
                              <stop
                                offset="0%"
                                stop-opacity="0"
                                stop-color="currentColor"
                              ></stop>
                              <stop
                                offset="100%"
                                stop-opacity="1"
                                stop-color="currentColor"
                              ></stop>
                            </linearGradient>
                          </defs>

                          <g stroke-width="1.5">
                            <path
                              stroke="url(#vykcgdhtpd)"
                              d="M 196 100 A 96 96 0 0 1 4 100"
                            ></path>
                          </g>
                          <animateTransform
                            from="90 0 0"
                            to="-270 0 0"
                            attributeName="transform"
                            type="rotate"
                            repeatCount="indefinite"
                            dur="10300ms"
                          ></animateTransform>
                        </svg>
                      </div>
                    </div>
                    <div className="exclusive-contest">
                      <h4>Complete the deposit </h4>
                      <p>
                        Register an account to get a crypto mystery box lottery
                        opportunity worth up to 1,000 USDT with a 100% winning
                        rate.
                      </p>
                      <button
                        className="btn btn-secondary btn-bitbab2"
                        onClick={() => navigate("/deposit/crypto")}
                      >
                        Deposit now
                        <span>
                          <i class="bi bi-arrow-right"></i>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="exclusive-box">
                  <div class="item--inner-bg_active ExclusiveModule_item--inner-bg_1e7_H">
                    <div className="box-icon">
                      <img
                        src={require("../../../assets/newUi/img/trade.png")}
                        className="img-fluid"
                      />
                      <div className="animation-exclusive">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="202.35"
                          height="202.35"
                          viewBox="0 0 200 200"
                          fill="none"
                          color="#FFF496"
                        >
                          <defs>
                            <linearGradient id="vykcgdhtpd">
                              <stop
                                offset="0%"
                                stop-opacity="0"
                                stop-color="currentColor"
                              ></stop>
                              <stop
                                offset="100%"
                                stop-opacity="1"
                                stop-color="currentColor"
                              ></stop>
                            </linearGradient>
                          </defs>

                          <g stroke-width="1.5">
                            <path
                              stroke="url(#vykcgdhtpd)"
                              d="M 196 100 A 96 96 0 0 1 4 100"
                            ></path>
                          </g>
                          <animateTransform
                            from="90 0 0"
                            to="-270 0 0"
                            attributeName="transform"
                            type="rotate"
                            repeatCount="indefinite"
                            dur="10300ms"
                          ></animateTransform>
                        </svg>
                      </div>
                    </div>
                    <div className="exclusive-contest">
                      <h4>Complete the trade </h4>
                      <p>
                        If your first transaction reaches 20 USDT, you will get
                        a crypto mystery box lottery opportunity worth up to
                        1,000 USDT, and you will 100% win.
                      </p>
                      <button
                        className="btn btn-secondary btn-bitbab2"
                        onClick={() => navigate("/spot/BTC_USDT")}
                      >
                        Trade now
                        <span>
                          <i class="bi bi-arrow-right"></i>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-lg-12">
                <div className="view-more">
                  <button className="btn btn-secondary btn-bitbab3">
                    View more
                    <span>
                      <i class="bi bi-arrow-right"></i>
                    </span>
                  </button>
                </div>
              </div>
            </div> */}
          </div>
        </section>

        <section className="trends-market">
          <div className="secure-box">
            <video autoPlay loop muted className="object-cover">
              <source
                src={require("../../../assets/newUi/img/story-bg.mp4")}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
            <div className="market-trends pt-5">
            <Row className="justify-content-md-center bg-content">
              <Col xxl={10} lg={11}>
                <div className="main-title text-center mb-5">
                  <h2>Market Trends</h2>
                  <p>Start learning digital currency trading now</p>
                </div>
                <div className="market-trade-tabs-banner-section">
                  <HomeMarket />
                </div>
              </Col>
            </Row>
          </div>
          </div>
          
        </section>

        <section className="new-user-guide">
          <div className="container">
            <div className="mt-5">
              <div className="main-title text-center mb-5">
                <h2>New User Guide</h2>
                <p>Start learning digital currency trading now</p>
              </div>
            </div>
            <div className="grid grid-cols-3 main-new-guide">
              <div className="user-guide-box bor-right">
                <div className="user-guide-icon">
                  <img
                    src={require("../../../assets/newUi/img/user.png")}
                    className="img-fluid"
                  />
                </div>
                <div className="user-guide-title">
                  <h4>How to conduct spot transactions</h4>
                </div>
                <div className="user-guide-details">
                  <p>
                    Spot trading allows you to buy tokens such as BTC directly,
                    with the advantages of high liquidity, real-time execution
                    and no waiting time for settlement between the two parties.
                  </p>
                  <button className="btn btn-secondary btn-bitbab3">
                    View more
                    <span>
                      <i class="bi bi-arrow-right"></i>
                    </span>
                  </button>
                </div>
              </div>
              <div className="user-guide-box  bor-right">
                <div className="user-guide-icon">
                  <img
                    src={require("../../../assets/newUi/img/task.png")}
                    className="img-fluid"
                  />
                </div>
                <div className="user-guide-title">
                  <h4>How to conduct spot transactions</h4>
                </div>
                <div className="user-guide-details">
                  <p>
                    Spot trading allows you to buy tokens such as BTC directly,
                    with the advantages of high liquidity, real-time execution
                    and no waiting time for settlement between the two parties.
                  </p>
                  <button className="btn btn-secondary btn-bitbab3">
                    View more
                    <span>
                      <i class="bi bi-arrow-right"></i>
                    </span>
                  </button>
                </div>
              </div>
              <div className="user-guide-box">
                <div className="user-guide-icon">
                  <img
                    src={require("../../../assets/newUi/img/trade.png")}
                    className="img-fluid"
                  />
                </div>
                <div className="user-guide-title">
                  <h4>How to conduct spot transactions</h4>
                </div>
                <div className="user-guide-details">
                  <p>
                    Spot trading allows you to buy tokens such as BTC directly,
                    with the advantages of high liquidity, real-time execution
                    and no waiting time for settlement between the two parties.
                  </p>
                  <button className="btn btn-secondary btn-bitbab3">
                    View more
                    <span>
                      <i class="bi bi-arrow-right"></i>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="secured-easy">
          <div className="container">
            <div className="bg-secure-trend">
              <img
                src={require("../../../assets/newUi/img/downloadIm.png")}
                className="img-fluid"
              />
            </div>
            <div className="mt-5">
              <div className="main-title text-center mb-5">
                <h2>A Secure and easy-to-use Exchange</h2>
                <p>
                  Download the Bitbab mobile app to keep an eye on the markets
                  and make trades at the perfect moment, wherever you are.
                </p>
              </div>
            </div>
            <div className="social-icons-box">
              <div className="social-icons-1">
                <img
                  src={require("../../../assets/newUi/img/googleplay.png")}
                  className="img-fluid"
                />
              </div>
              {/* <div className="social-icons-1">
                <img
                  src={require("../../../assets/newUi/img/appstore.png")}
                  className="img-fluid"
                />
              </div> */}
            </div>
            <div className="download-exchange">
              <div className="exchange-profile">
                <img
                  src={require("../../../assets/newUi/img/app-thumb-desktop.png")}
                  className="img-fluid"
                />
              </div>
              <div className="exchange-profile-mobile">
                <img
                  src={require("../../../assets/newUi/img/app-thumb-mobile.png")}
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="every-trad">
              <h4>
                Every trade you make will help us advance human exploration of
                Mars and Space！
              </h4>
              <div class="bg-box-trading">
                <img
                  src={require("../../../assets/newUi/img/bg-box-trading.png")}
                  className="img-fluid"
                />
              </div>
              <button
                className="btn btn-secondary btn-bitbab3"
                onClick={() => navigate("/spot/BTC_USDT")}
              >
                Trade Now
                <span>
                  <i class="bi bi-arrow-right"></i>
                </span>
              </button>
            </div>
          </div>
        </section>

        <Footer />
      </Container>
    </>
  );
};

export default MainBanner;
