import React, { useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { FiChevronUp, FiChevronDown } from "react-icons/fi";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import { getCookie, setCookie } from "../../../core/helper/cookie";
import { toast } from "../../../core/lib/toastAlert";
import { showPairName } from "../../../core/helper/date-format";
import btc from "../../../assets/images/btc.png";
let initialFavs = getCookie("userPairs");

export default function PairListAndSearchCopy(props) {
  const [isActive, setActive] = useState(false);

  const ToggleClass = () => {
    setActive(!isActive);
  };

  useEffect(() => {
    $(".spot-page-pair-select-dropdown .dropdown-menu").click(function (e) {
      e.stopPropagation();
    });
  }, []);

  const navigate = useNavigate();
  const { decimalValue, pairDetails = {}, marketList: markets = [] } = props;

  const [favPairsList, setFavPairsList] = useState([]);
  const [favPairs, setFavPairs] = useState(getCookie("userPairs"));
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [selectedTabCurrency, setSelectedTabCurrency] = useState("");
  const [listMarkets, setListMarkets] = useState([]);
  // const [toCurrencySymbolFinal, settoCurrencySymbolFinal] = useState("");
  const [searchVal, setsearchVal] = useState("");
  const [selectedMarkets, setSelectedMarkets] = useState(
    props.marketList && props.marketList.length > 0
      ? props.marketList[0].pairs
      : []
  );

  // useEffect(() => {
  //   loadSocket()
  // }, []);

  // const loadSocket = () => {
  //   props.socketConnection && props.socketConnection.on("USDTPerpetualPairResp", function (data) {
  //     console.log("USDTPerpetualPairResp : 11 : ", data);
  //     if(data && data._id) {
  //       changeSocData(data);
  //     }
  //   });
  // }

  props.socketConnection &&
    props.socketConnection.on("USDTPerpetualPairResp", function (data) {
      if (data && data._id) {
        changeSocData(data);
      }
    });

  function changeSocData(data) {
    const iDx = listMarkets.findIndex((e) => e._id == data._id);

    if (iDx > -1) {
      let listMarketsCopy = [...listMarkets, ...[]];
      let valChanged = false;

      if (listMarketsCopy[iDx].marketPrice != data.marketPrice) {
        valChanged = true;
        listMarketsCopy[iDx].marketPrice = data.marketPrice;
      }
      if (listMarketsCopy[iDx].price_24h_pcnt != data.price_24h_pcnt) {
        valChanged = true;
        listMarketsCopy[iDx].price_24h_pcnt = data.price_24h_pcnt;
      }
      if (valChanged == true) {
        setListMarkets(listMarketsCopy);
      }
    }
  }

  useEffect(() => {
    if (initialFavs != favPairs) {
      if (selectedCurrency == "") {
        setPairs("");
      }
    }
  }, [favPairs]);

  function checkFavourite(pairName) {
    const markedPairs = getCookie("userPairs");
    if (markedPairs != null && markedPairs != "") {
      let favPairs = markedPairs.split(",");
      if (favPairs.indexOf(pairName) >= 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  function setFavourite(pairName) {
    const markedPairs = getCookie("userPairs");
    let favPairsNew = [];
    if (markedPairs != null && markedPairs != "") {
      favPairsNew = markedPairs.split(",");
    }
    if (favPairsNew.indexOf(pairName) >= 0) {
      favPairsNew.splice(favPairsNew.indexOf(pairName), 1);
      toast({
        type: "success",
        message: "Favourite Pair Removed Successfully!",
      });
    } else {
      favPairsNew.push(pairName);
      toast({ type: "success", message: "Favourite Pair Added Successfully!" });
    }
    setNewFavList(favPairsNew);
    setFavPairs(favPairsNew.join(","));
    setCookie("userPairs", favPairsNew.join(","));
  }

  function setCurrency(currency) {
    console.log("setCurrency:", currency, selectedCurrency);
    if (currency != selectedCurrency) {
      setSelectedCurrency(currency);
      setPairs(currency);
    }
  }

  function setPairs(currency) {
    if (currency != "") {
      markets.map((item) => {
        if (item.currency == currency) {
          setSelectedMarkets(item.pairs);
        }
      });
    } else {
      const markedPairs = getCookie("userPairs");
      if (markedPairs != null && markedPairs != "") {
        let favouritePairs = [];
        let favPairs = markedPairs.split(",");
        markets.map((item) => {
          item.pairs.map((item1) => {
            if (favPairs.indexOf(item1.pair) >= 0) {
              favouritePairs.push(item1);
            }
          });
        });
        setSelectedMarkets(favouritePairs);
      } else {
        setSelectedMarkets([]);
      }
    }
  }

  useEffect(() => {
    if (props.toCurrencySymbol != selectedCurrency && selectedCurrency == "") {
      setSelectedCurrency(props.toCurrencySymbol);
    }
  }, [props]);

  function setNewFavList(favPairsUser = []) {
    if (selectedCurrency == "fav") {
      let newMarketList = [];
      for (let i = 0; i < markets.length; i++) {
        const marketsPairGrp = markets[i].pairs;
        console.log({ marketsPairGrp });
        for (let j = 0; j < marketsPairGrp.length; j++) {
          const marketsRow = marketsPairGrp[j];
          const favPairChk = favPairsUser.indexOf(marketsRow.pair);
          if (favPairChk > -1) {
            newMarketList.push(marketsRow);
          }
        }
      }
      setListMarkets(newMarketList);
    }
  }

  useEffect(() => {
    if (selectedCurrency) {
      if (selectedCurrency == "fav") {
        let favPairsUser = favPairs ? favPairs.split(",") : [];
        setNewFavList(favPairsUser);
      } else {
        const idx = markets.findIndex(
          (elem) => elem.currency == selectedCurrency
        );
        if (idx > -1) {
          setListMarkets(markets[idx].pairs);
        } else {
          setListMarkets([]);
        }
      }
    }
  }, [selectedCurrency, markets]);

  const { fromCurrency = {}, toCurrency = {} } = pairDetails;

  const { siteDecimal: siteDecimal_from = 2 } = fromCurrency;

  const { siteDecimal: siteDecimal_to = 2 } = toCurrency;

  return (
    <div className="row p-2 currency-container">
      <div className="col-lg-2 mobile-token-container">
        <div className="row">
          <div className="col-lg-2 d-flex align-items-center p-1 mobile-justify-content-center">
            <img src={btc} alt="btc-icon" className="btc-icon" />
          </div>
          <div className="col-lg-9 p-2 pt-0 pb-0 token-container ">
            <div className="row">
              <div className="col-lg-12 mobile-justify-content-center">
                <span className="currency-primary">BTC/USDT</span>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 d-flex align-items-center mobile-justify-content-center">
                <span className="currency-secoundary ">Bitcoin</span>
                <span className="currency-pipe-border">|</span>
                <span className="currency-secoundary">BTC Network</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-1 col-md-6 col-sm-6 pt-2">
        <div className="pair-primary">
          <span className="color-green-price">
            {pairDetails && pairDetails?.price
              ? props?.decimalValue(pairDetails?.price, 4, "removeZero")
              : 0}
          </span>
        </div>
        <div className="pair-secoundary">
          <span>
            {" "}
            ≈
            {pairDetails && pairDetails?.price
              ? props?.decimalValue(pairDetails?.price, 4, "removeZero")
              : 0}{" "}
            USD
          </span>
        </div>
      </div>
      <div className="col-lg-1 col-md-6 col-sm-6 pt-2">
        <div className="price-secoundary mobile-justify-end">24H Change</div>
        <div className="price-primary mobile-justify-end">
          {pairDetails && pairDetails?.pair != undefined ? (
            <>
              <span
                className={
                  pairDetails?.change >= 0
                    ? "color-green-price"
                    : "color-darkpink"
                }
              >
                {(pairDetails?.change >= 0 ? "+" : "") +
                  props.decimalValue(pairDetails?.change, 2)}
                %
              </span>
            </>
          ) : (
            "-"
          )}
        </div>
      </div>
      <div className="col-lg-1 col-md-6 col-sm-6 pt-2">
        <div className="price-secoundary">24H High</div>
        <div className="price-primary">
          {pairDetails && pairDetails?.pair != undefined
            ? props?.decimalValue(
                pairDetails?.high,
                pairDetails?.priceDecimal,
                "removeZero"
              )
            : "-"}
        </div>
      </div>
      <div className="col-lg-1 col-md-6 col-sm-6 pt-2">
        <div className="price-secoundary mobile-justify-end">24H Low</div>
        <div className="price-primary mobile-justify-end">
          {pairDetails && pairDetails?.pair != undefined
            ? props?.decimalValue(
                pairDetails?.high,
                pairDetails?.priceDecimal,
                "removeZero"
              )
            : "-"}
        </div>
      </div>
      <div className="col-lg-1 col-md-6 col-sm-6 pt-2">
        <div className="price-secoundary">
          24h Vol(
          {pairDetails?.fromCurrency &&
            pairDetails?.fromCurrency?.currencySymbol}
          )
        </div>
        <div className="price-primary">
          {pairDetails && pairDetails?.volume_fromCur != undefined
            ? props?.decimalValue(
                pairDetails?.volume_fromCur,
                pairDetails?.amountDecimal
              )
            : "-"}
        </div>
      </div>
      <div className="col-lg-1 col-md-6 col-sm-6 pt-2">
        <div className="price-secoundary mobile-justify-end">
          24h Vol(
          {pairDetails?.toCurrency && pairDetails?.toCurrency?.currencySymbol})
        </div>
        <div className="price-primary mobile-justify-end">
          {pairDetails && pairDetails?.volume != undefined
            ? props.decimalValue(
                pairDetails?.volume,
                pairDetails?.amountDecimal
              )
            : "-"}
        </div>
      </div>
    </div>
  );
}
