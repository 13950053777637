const {
    REACT_APP_ENVNAME: environ = "development"
} = process.env;

let envPath;
if(environ === "development") {
	envPath = "common";
}
else if(environ !== "development") {
	envPath = "main";
}
module.exports = require("./"+envPath+"/"+environ+"_rpc.js");