import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Link, useNavigate } from "react-router-dom";
import $ from "jquery";

import {
  FaInstagram,
  FaTwitter,
  FaLinkedin,
  FaFacebookSquare,
  FaTelegramPlane,
  FaYoutube,
} from "react-icons/fa";
import { RiTwitterXFill } from "react-icons/ri";

import { useContextData } from "../../../core/context/index";

import { getHomeCMS } from "../../../core/services/all.api";

import Config from "../../../core/config/index";

const FooterV2 = (props) => {
  const navigate = useNavigate();

  const { siteSettings } = useContextData();

  const [cmsDynData, setCmsDynData] = useState({});

  useEffect(() => {
    getFooterCMSCall();
  }, []);

  const isValidHttpUrl = async (val = "") => {
    let url;
    try {
      url = new URL(val);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
    // return val.search("http");
  };

  const navigateTo = async (linkVal) => {
    if (linkVal) {
      if (await isValidHttpUrl(linkVal)) {
        window.open(linkVal);
      } else {
        navigate(linkVal, true);
      }
    }
  };

  const getFooterCMSCall = async () => {
    const payload = { from: "footer" };
    const resp = await getHomeCMS({ payload });
    if (resp) {
      const { getcmsDetails = [], status = false } = resp;
      if (status) {
        let CMSList = getcmsDetails.filter((elem) => elem.identify == "CMS");
        const updateCmdData = {
          CMSList: CMSList && CMSList.length > 0 ? CMSList : [],
        };
        setCmsDynData(updateCmdData);
      }
    }
  };
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__main">
          <div className="row">
            <div className="col-xl-4 col-md-8">
              <div className="info">
                {/* <img
                  src={require("../../../assets/newUi/img/bitbab.png")}
                  className="logo"
                  width="200px;"
                /> */}
                {(siteSettings && siteSettings.siteLogo) ?
                  <img className='logo' src={siteSettings.siteLogo} alt="logo" onClick={() => navigate('/')} /> : ""
                }
                <ul className="list">
                  {/* <li>
                    <p>+12 345 678 9101</p>
                  </li> */}
                  {siteSettings && siteSettings.supportEmail &&
                  <li><p><a href={"mailto:" + siteSettings.supportEmail}>{siteSettings.supportEmail}</a></p></li>
                  }
                  <li>
                    <p>
                      {(siteSettings && siteSettings.copyRights) ? siteSettings.copyRights : ""}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-2 col-md-4">
              <div className="widget-link s1">
                <h6 className="title">PRODUCTS</h6>
                <ul>
                  <li>
                    <Link to={'/spot/BTC_USDT'}>Exchange</Link>
                  </li>
                  <li>
                    <Link to={'/trade/all-payments'}>P2P</Link>
                  </li>
                  <li>
                    <Link to={'/swap'}>Swap</Link>
                  </li>
                  <li>
                    <Link to={'/staking'}>Staking</Link>
                  </li>
                  <li>
                    <Link to={'/spot/BTC_USDT'}>Exchange</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-2 col-md-4">
              <div className="widget-link s2">
                <h6 className="title">SERVICES</h6>
                <ul>
                  <li>
                    <Link to={'/markets'}>Market</Link>
                  </li>
                  <li>
                    <Link to={'/fee-structure'}>Fees</Link>
                  </li>
                  <li>
                    <Link to={'/coin-status'}>Coin Status</Link>
                  </li>
                  <li>
                    <Link to={'/my/referral'}>Refer & Earn</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-2 col-md-4">
              <div className="widget-link s3">
                <h6 className="title">SUPPORT</h6>
                <ul>
                  <li>
                    <Link to={'/contact-support'}>Contact Support</Link>
                  </li>
                  <li>
                    <Link to={'/contact-support'}>Coin Listing</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-2 col-md-4">
              <div className="widget-link s4">
                <h6 className="title">ABOUT US</h6>
                <ul>
                  <li>
                  <Link to={'/about'}>About</Link>
                  </li>
                  <li>
                    <Link to={'/faq'}>FAQ's</Link>
                  </li>
                  <li>
                    <Link to={'/terms'}>Terms & Condition</Link>
                  </li>
                  <li>
                    <Link to={'/privacy-policy'}>Privacy Policy</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterV2;
