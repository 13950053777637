import React, { useEffect, useState } from "react";
import $ from "jquery";

// import NavbarV1 from "./NavbarV1";
import MainHeader from "../newUi/Home/MainHeader";

// oldUi - CSS
import "../../assets/globalVar.css";
import "../../assets/style.css";
import "../../assets/custom.css";
import "../../assets/styledev.css";
import "../../assets/modes.css";

export default function NavbarOne(props) {

  useEffect(() => {
    $(document).ready(function () {
      if(window.location.pathname === "/" || window.location.pathname === "/homev2") {
        $("body").addClass("dark-new");
        $("body").removeClass("light-new");
        $("#themesection-body").addClass("dark-new");
        $("#themesection-body").removeClass("light-new");
      }
      else if (props.theme === "light-new") {
        // alert(props.theme+"---")
        $("body").addClass("light-new");
        $("body").removeClass("dark-new");
        $("#themesection-body").addClass("light-new");
        $("#themesection-body").removeClass("dark-new");
      } else {
        $("body").addClass("dark-new");
        $("body").removeClass("light-new");
        $("#themesection-body").addClass("dark-new");
        $("#themesection-body").removeClass("light-new");
      }
      // if ($("#themesection-body").hasClass("light-new")) {
      //   $("body").addClass("light-new");
      //   $("body").removeClass("dark-new");
      // } else {
      // }
    });
  }, []);
  return (
    // <NavbarV1
    //   setTheme={props.setTheme}
    //   theme={props.theme}
    // />
    <MainHeader
      setTheme={props.setTheme}
      theme={props.theme}
      identify={props.identify}
    />
  );
}