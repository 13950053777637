import React, { useEffect, useState } from 'react';

// import FooterV1 from "./FooterV1";
import FooterV2 from "../newUi/Home/FooterV2";

const Footer = () => {
    return (
        <FooterV2 />
    );
}

export default Footer;
