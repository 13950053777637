import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';

import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Dropdown from 'react-bootstrap/Dropdown';
import { BiUser, BiMoney, BiUserPlus, BiWallet } from "react-icons/bi";
//import Logo from '../src/assets/img/bitbab.png';
import Col from 'react-bootstrap/Col';
import "../../../assets/newUi/css/Banner.css";
import "../../../assets/newUi/css/mainheader.css";

import lightSvg from "../../../assets/images/mode-images/light.svg";
import darkSvg from "../../../assets/images/mode-images/dark.svg";

// import light from "../../../assets/images/mode-images/theme-light.png";
// import dark from "../../../assets/images/mode-images/theme-dark.png";
// import goldSun from "../../../assets/images/mode-images/gold-sun.png";
// import goldmoon from "../../../assets/images/mode-images/gold-moon.png";

import jwt_decode from "jwt-decode";

import { useContextData } from "../../../core/context/index";
import { getCookie, deleteCookie } from "../../../core/helper/cookie";
import Config from "../../../core/config/";

export default function Header(props) {
  const navigate = useNavigate();

  $(window).scroll(function() {
    var scroll = $(window).scrollTop();
    if (scroll >= 60) {
      $('.bg-nav').addClass("fixed");
      $('#header_menu').addClass("fixed");
    } else {
      $('#header_menu').removeClass("fixed");
    }
  });

  const { siteSettings, myProfile, setUserProfile } = useContextData();
  const [userLogin, setUserLogin] = useState(false);

  const loginChk = async (event) => {
    const token = getCookie("userToken");
    const decodedToken = token ? jwt_decode(token, { header: true }) : {};
    if (decodedToken.typ === "JWT") {
      setUserLogin(true);
    }
  };

  useEffect(() => {
    loginChk();
  }, [myProfile]);

  return (
    <Navbar expand="lg" className="bg-body-tertiary bg-nav {headerClassName}" id="header_menu">
      <Container fluid>
        <Navbar.Brand href="#">
          <Link to="/" >
            <img src={require('../../../assets/newUi/img/bitbab.png')} className="logo" width="200px;" alt="home"/>
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className='nav-bg justify-content-center'>
          <Nav className="main-header-nav">
            <Link to="/markets" className={props.identify == "markets" ? "active" : ""}>Markets</Link>
            <NavDropdown title="Trade" id="basic-nav-dropdown" className={(props.identify == "spot" || props.identify == "futures" || props.identify == "p2p") ? "ddm-active" : ""}>
              <NavDropdown.Item href="/spot/BTC_USDT" className={(props.identify == "spot") ? "ddm-active" : ""}>Spot</NavDropdown.Item>
              <NavDropdown.Item href="/futures/BTC_USDT" className={(props.identify == "futures") ? "ddm-active" : ""}>USD-M Futures</NavDropdown.Item>
              <NavDropdown.Item href="/trade/all-payments" className={(props.identify == "p2p") ? "ddm-active" : ""}>P2P</NavDropdown.Item>
              {/* <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item> */}
            </NavDropdown>
            {Config.CONVERT_STATUS == "Enable" ? (
            <Link to="/swap" className={props.identify == "swap" ? "active" : ""}>Swap</Link>
            ) : ("")}
            { Config.STAKING_STATUS == "Enable" ? (
            <Link to="/staking" className={props.identify == "staking" ? "active" : ""}>Staking</Link>
            ) : ("")}
            <Link to="/faq" className={props.identify == "faq" ? "active" : ""}>FAQs</Link>
            <Link to="/about" className={props.identify == "about" ? "active" : ""}>About</Link>
          </Nav>
        </Navbar.Collapse>
        <Col xs="auto">
        <Navbar.Collapse xs="auto" className="nav-end">
          {userLogin === false ? (
          <>
            <Nav.Link href="/login">Login</Nav.Link>
            <Nav.Link href="/register" className='reg-btn'>Register</Nav.Link>
          </>
          ):(
            <Dropdown>
              <Dropdown.Toggle className="profile-dropdown" variant="" id="dropdown-basic">
                <BiUser />{" "}  Profile
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="#"
                  onClick={() => navigate("/my/profile")}
                >
                  <BiUser /> { myProfile ? (myProfile.email ? myProfile.email : myProfile.phoneno) : ""}{" "}
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item href="#"
                  onClick={() => navigate("/my/wallet")}
                >Wallet</Dropdown.Item>
                <Dropdown.Item href="#"
                  onClick={() => navigate("/my/wallet/history")}
                >Transactions</Dropdown.Item>
                <Dropdown.Item href="#"
                  onClick={() => {
                    deleteCookie("userToken");
                    setUserProfile();
                    navigate("/login");
                  }}
                >Log out</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            // {myProfile && (
            //   <Nav.Link href="/my/profile" className='reg-btn'>
            //     {myProfile.email
            //       ? myProfile.email
            //       : myProfile.phoneno}{" "}
            //   </Nav.Link>
            // )}
          )}
          {window.location.pathname !== "/" ?
            <ul className="switch-ul">
              <li>
                <div>
                  <span className="item" >
                    <img src={props.theme == "light-new" ? lightSvg : darkSvg} alt="theme" onClick={() => {
                    const mode = props.theme == "light-new" ? "dark-new" : "light-new";
                    props.setTheme(mode);
                    localStorage.setItem("mode", mode);
                  }}/>
                  </span>
                </div>
                {/* <div className="switch">
                  <span className={props.theme == "light-new" ? 'dark-active item' : 'item'}>
                    {
                      props.theme == 'light-new' && <img src={goldSun} alt="dark" onClick={() => {
                        const mode = 'light-new';
                        props.setTheme(mode);
                        localStorage.setItem("mode", mode);
                      }} />
                    }
                    {
                      props.theme != 'light-new' && <img src={light} alt="dark" onClick={() => {
                        const mode = 'light-new';
                        props.setTheme(mode);
                        localStorage.setItem("mode", mode);
                      }} />
                    }
                  </span>
                  <span className={props.theme == "dark-new" ? 'dark-active item' : 'item'}>
                      {
                        props.theme == 'dark-new' && <img src={goldmoon} alt="dark" className="dark-img" onClick={() => {
                          const mode = 'dark-new';
                          props.setTheme(mode);
                          localStorage.setItem("mode", mode);
                        }} />
                      }
                      {
                        props.theme != 'dark-new' && <img src={dark} alt="dark" className="dark-img" onClick={() => {
                          const mode = 'dark-new';
                          props.setTheme(mode);
                          localStorage.setItem("mode", mode);
                        }} />

                      }
                    </span>
                </div> */}
              </li>
            </ul>:""}
        </Navbar.Collapse>
        </Col>
      </Container>
    </Navbar>
  )
}

// export default header