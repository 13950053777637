const RPCDETAILS = {
  ERC20: {
    explorer: {
      landing: "https://etherscan.io/",
      txnLink: "https://etherscan.io/tx/",
      token: "https://etherscan.com/token/",
    },
  },
  BEP20: {
    explorer: {
      landing: "https://bscscan.com/",
      txnLink: "https://bscscan.com/tx/",
      token: "https://bscscan.com/token/",
    },
  },
  TRC20: {
    explorer: {
      landing: "https://tronscan.org/",
      txnLink: "https://tronscan.org/#/transaction/",
      token: "https://tronscan.org/#/token20/",
    },
  },
};

export default RPCDETAILS;
